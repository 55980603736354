import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import UserAPI from "../Service/User";
import CustomModal from "../Components/CustomModal";
import User from "../Service/User";
import { PaginationControl } from 'react-bootstrap-pagination-control';

import {
  Button,
  FormGroup,
  Label,
  Input
} from "reactstrap";

export default function ListUser() {
  const [page, setPage] = useState(1);
  const [allUsers, setAllUsers] = useState([]);
  const [count, setCount] = useState(5);
  const [modalAdd, setModalAdd] = useState(false);
  const [state, setState] = useState({});
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [txtSearch,setTxtSearch] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [title, setTitle] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [id, setId] = useState("");
  const [modelModify, setModelModify] = useState(false);
  async function getInit() {
    UserAPI.getAll({ page: 0 ,search:txtSearch})
      .then((data) => {
        setAllUsers(data.data);
        setCount(data.count);
      })
      .catch(function (ex) {});
  }
  const handleValidation = (event) => {
    let formIsValid = true;

    // if (!email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
    //   formIsValid = false;
    //   setEmailError("Email không tồn tại");
    //   return false;
    // } else {
    //   setEmailError("");
    //   formIsValid = true;
    // }

    if (!password.match(/^[a-zA-Z]{8,22}$/)) {
      // formIsValid = false;
      // setPasswordError(
      //   "Mật khẩu phải có ký tự hoa, ký tự thường và 1 ký tự đăc biệt gồm 8 đến 22 ký tự"
      // );
      // return false;
    } else {
      setPasswordError("");
      formIsValid = true;
    }

    return formIsValid;
  };
  const handleCloseModal = () => {
    setModalAdd(false);
  };
  const listItems = allUsers.map((item,index) =>
    <tr key={index}>
      <td>{item.userName.toString()}</td>
      <td>{item.name.toString()}</td>
      <td>{item.email.toString()}</td>
      <td>{item.role.toString()}</td>
      <td className="text-center">
        <Button
          className="btn btn-success"
          onClick={() => modifyUserModel(item)}
        >
          Sửa
        </Button>
        <span>||</span>
        <Button className="btn btn-danger" onClick={() => deleteUserModel(item)}>Xóa</Button>
      </td>
    </tr>
    );
  function handleSubmit() {
    const formIsValid=handleValidation();
    if(formIsValid)
    {
      User.signUp({ email,name, userName, password,role });
      debugger
      setModalAdd(false);
    }
  }

  function addUser() {
    setId("");
    setUserName("");
    setPassword("");
    setRole("");
    setEmail("");
    setTitle("Thêm Tài Khoản");
    setModalAdd(true);
  }
  function modifyUserModel(data) {
    setUserName(data.userName);
    setEmail(data.email);
    setId(data.id);
    setRole(data.role);
    setName(data.name);
    setTitle("Sửa Thông Tin Tài khoản");
    setModelModify(true);
  }
  function deleteUserModel(data) {
    setTitle("Xóa Tài khoản");
    setUserName(data.userName);
    setConfirmModal(true);
  }
  function changePage(num){
    UserAPI.getAll({ page: num-1 })
      .then((data) => {
        setAllUsers(data.data);
        setCount(data.count);
      })
      .catch(function (ex) {});

  }
  function deleteUser(user){
    UserAPI.deleteUser({ userName: user });
    setAllUsers()
    const newList = allUsers.filter((item) => item.userName !== user);
    setAllUsers(newList);
  }
  function modifyUser(){
    User.modifyUser({ email,name, userName, password,role });
    setModelModify(false);
    getInit();
  }
  useEffect(() => {
    getInit();
  }, []);
  function search(){
    getInit();
  }
  return (
    <div className="container">
      <h4>Danh sách tài khoản</h4>
      <Input type="text" style={{ float: "left" ,margin:"20px", width:"250px"}} onChange={(e) => setTxtSearch(e.target.value)} placeholder="Search"/>
      <Button
        style={{ float: "right" ,margin:"20px"}}
        color="secondary"
        onClick={addUser}
      >
        Thêm tài khoản
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Tên Đăng Nhập</th>
            <th>Tên</th>
            <th>Email</th>
            <th>Quyền</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {listItems}
        </tbody>
      </Table>
      <PaginationControl
        page={page}
        between={4}
        total={count}
        limit={7}
        changePage={(page) => {
          setPage(page); 
          changePage(page);
        }}
        ellipsis={1}
      />
      <CustomModal
        title={title}
        isOpen={confirmModal}
        toggle={() => {
          setConfirmModal(false);
        }}
        onCancel={() => {
          setConfirmModal(false);
        }}
        cancelText="Hủy"
        onSubmit={() => {
          deleteUser(userName);
          setConfirmModal(false);
        }}
        submitText={"Xóa"}
      >
        Bạn có muốn muốn xóa tài khoản {userName}?
      </CustomModal>

      <CustomModal
        title={title}
        isOpen={modelModify}
        toggle={()=>setModelModify(false)}
        onCancel={()=>setModelModify(false)}
        onSubmit={modifyUser}
        submitText={"Lưu"}
      >
        <FormGroup>
          <Label for="exampleEmail">Tài Khoản:    {userName}</Label>
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Tên</Label>
          <Input
            type="text"
            name="Tên"
            placeholder="Tên"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Mật Khẩu</Label>
          <br/>
          <small  className="text-danger form-text">(ký tự hoa, ký tự thường và 1 ký tự đăc biệt gồm 8 đến 22 ký tự)</small>
          <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Mật Khẩu" class="form-control" />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Email</Label>
          <Input
            type="text"
            name="Email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Quyền</Label>
          <Input
            type={"select"}
            name="Quyền"
            placeholder="Quyền"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="" hidden></option>
          <option value={"Quản trị"}>Quản trị</option>
          <option value={"Người dùng"}>Người dùng</option>
          </Input>
        </FormGroup>
      </CustomModal>

      <CustomModal
        title={title}
        isOpen={modalAdd}
        toggle={handleCloseModal}
        onCancel={handleCloseModal}
        onSubmit={handleSubmit}
        submitText={"Lưu"}
      >
        <FormGroup>
          <Label for="exampleEmail">Tài Khoản</Label>
          <Input
            type="text"
            name="Tài khoản"
            placeholder="Tài Khoản"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Tên</Label>
          <Input
            type="text"
            name="Tên"
            placeholder="Tên"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Mật Khẩu</Label>
          <br/>
          <small  className="text-danger form-text">(ký tự hoa, ký tự thường và 1 ký tự đăc biệt gồm 8 đến 22 ký tự)</small>
          <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Mật Khẩu" class="form-control" />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Email</Label>
          <Input
            type="text"
            name="Email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Quyền</Label>
          <Input
            type={"select"}
            name="Quyền"
            placeholder="Quyền"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="" hidden></option>
          <option value={"Quản trị"}>Quản trị</option>
          <option value={"Người dùng"}>Người dùng</option>
          </Input>
        </FormGroup>
      </CustomModal>
    </div>
  );
}
