import React, { useState } from "react";
import User from "../Service/User";

export default function Login({ setToken }) {
  let [authMode, setAuthMode] = useState("signin");
  const [btn, setBtn] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [createUser, setCreateUser] = useState("");

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };
  const handleValidation = (event) => {
    let formIsValid = true;

    if (!email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
      formIsValid = false;
      setEmailError("Email không tồn tại");
      return false;
    } else {
      setEmailError("");
      formIsValid = true;
    }

    if (!password.match(/^[a-zA-Z]{8,22}$/)) {
      // formIsValid = false;
      // setPasswordError(
      //   "Mật khẩu phải có ký tự hoa, ký tự thường và 1 ký tự đăc biệt gồm 8 đến 22 ký tự"
      // );
      // return false;
    } else {
      setPasswordError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    handleValidation();
    setBtn(true);
    User.signIn({ userName, password }).then((data) => {
        if(data.status===401){
          setLoginError("Thông tin đăng nhập sai !")
        }
        else{
          setToken(data.token);
          sessionStorage.setItem('role', data.role[0]);
          sessionStorage.setItem('userName', data.userName);
          sessionStorage.setItem('userId', data.id);

        }
        setBtn(false);

      })
      .catch(function (ex) {
        setLoginError("Thông tin đăng nhập sai !")
      });
  };
  const signUp = (e) => {
    e.preventDefault();
    const formIsValid= handleValidation();
    if(formIsValid)
    {
      User.signUp({ email, userName, password }).then((data) => {
        setCreateUser(data);
      });
    }
  };
  if (authMode === "signup") {
    return (
      <div className="col-md-4 offset-md-4 mt-4">
        <div className="card">
          <h4 className="card-header">Tạo tài khoản</h4>
          <div className="card-body">
            <div style={{textAlign: "center"}}>
              <img src="https://vijatech.com.vn/wp-content/uploads/2021/09/logo-png1.png" style={{width: "350px"}} alt="React Logo" />
            </div>
            <form id="loginform" onSubmit={signUp}>
              <div className="form-group">
                <label>Tài Khoản</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tài Khoản"
                  onChange={(event) => setUserName(event.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="EmailInput"
                  name="EmailInput"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  onChange={(event) => setEmail(event.target.value)}
                />
                <small id="emailHelp" className="text-danger form-text">
                  {emailError}
                </small>
              </div>
              <div className="form-group">
                <label>Mật Khẩu</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Mật Khẩu"
                  onChange={(event) => setPassword(event.target.value)}
                />
                <small id="passworderror" className="text-danger form-text">
                  {passwordError}
                </small>
              </div>
              <br/>
              <button type="submit" className="btn btn-primary">
                Tạo Tài Khoản
              </button>
            </form>
            <div>
              <label className="text-danger form-text">{createUser}</label>
            </div>
            <div className="text-center">
               Bạn đã có tài khoản ?{" "}
              <span className="link-primary" onClick={changeAuthMode}>
                Đăng Nhập
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="col-md-4 offset-md-4 mt-4">
        <div className="card">
          <div style={{textAlign: "center"}}>
              <img src="https://vijatech.com.vn/wp-content/uploads/2021/09/logo-png1.png" style={{width: "350px"}} alt="React Logo" />
          </div>
          <h2 style={{textAlign: "center"}}>Văn Phòng Điện Tử</h2>
          <h4 className="card-header">Đăng Nhập</h4>
          <div className="card-body">
            <form id="loginform" onSubmit={loginSubmit}>
              <div className="form-group">
                <label>Tài khoản</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tài khoản"
                  onChange={(event) => setUserName(event.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Mật Khẩu</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Mật Khẩu"
                  onChange={(event) => setPassword(event.target.value)}
                />
                <small id="passworderror" className="text-danger form-text">
                  {passwordError}
                </small>
              </div>
              <br/>
              <button type="submit" disabled={btn} className="btn btn-primary">
                Đăng Nhập
              </button>
            </form>
            <div>
              <label className="text-danger form-text">{loginError}</label>
            </div>
            {/* <div className="text-center">
              Chưa đăng ký?{" "}
              <span className="link-primary" onClick={changeAuthMode}>
                Tạo tài khoản
              </span>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
