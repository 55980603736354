import React,{ useState,useEffect } from "react";
import UserAPI from "../Service/User";
import User from "../Service/User";
import Alert from 'react-bootstrap/Alert';

import {
    Button,
    FormGroup,
    Label,
    Input
  } from "reactstrap";

export default function ChangeInf() {
    const [userName, setUserName] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");
    const [show, setShow] = useState(true);
    useEffect(() => {
        getInit();
      }, []);
    async function getInit() {
    UserAPI.getUserByName()
    .then((data) => {
        setEmail(data.email);
        setUserName(data.userName);
        setName(data.name);
        setRole(data.role);
    })
    .catch(function (ex) {});
    }

    const save = () => {
        User.modifyUser({ userName,name,email, password,role }).then((data) => {
          setShow(false);
        })
        .catch(function (ex) {});;
        sessionStorage.setItem('userName', name);       

      };
    return (
        <div className="container">
          <h3 align="center">Thay đổi thông tin tài khoản</h3>
        <FormGroup>
          <Label for="exampleEmail">Tài Khoản:    {userName}</Label>
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Tên</Label>
          <Input
            type="text"
            name="Tên"
            placeholder="Tên"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Mật Khẩu</Label>
          <br/>
          <small  className="text-danger form-text">(ký tự hoa, ký tự thường và 1 ký tự đăc biệt gồm 8 đến 22 ký tự)</small>
          <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Mật Khẩu" class="form-control" />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Email</Label>
          <Input
            type="text"
            name="Email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        <Alert variant="success" hidden={show}>Lưu thành công</Alert>  
        <Button onClick={() => save()}>Lưu</Button>
        </div>
      )
}