import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";

import DateRangePicker from "react-bootstrap-daterangepicker";
import esLocale from "@fullcalendar/core/locales/vi";

import events from "./events";
import CustomModal from "../Components/CustomModal";

import EventAPI from "../Service/EventAPI";

export default function CalendarAll() {
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [detail, setDetail] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const calendarRef = useRef(null);

  const [title, setTitle] = useState("");
  const [layout, setLayout] = useState({
    left: "prev,today,next",
    center: "title",
    right: "dayGridMonth,dayGridWeek,dayGridDay",
  });
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  var DAY_NAMES = ['CN','Thứ Hai','Thứ Ba','Thứ Tư','Thứ Năm','Thứ Sáu','Thứ Bảy',]
  const [allEvents, setAllEvents] = useState([]);
  useEffect(() => {
    getInit();
    if(window.innerWidth<700){
      setLayout(
        {
          left: "prev,today,next",
          right: "dayGridMonth,dayGridWeek,dayGridDay",
        })      
    }
  }, []);
  async function getInit() {
    EventAPI.GetAlls()
      .then((data) => {
        setAllEvents(data);
      })
      .catch(function (ex) {});
  }

  const handleCloseModal = () => {
    handleClose();
    setModal(false);
  };

  function handleDateClick(arg) {}
  function handleDateSelect(selectInfo) {
    if (
      selectInfo.view.type === "timeGridWeek" ||
      selectInfo.view.type === "timeGridDay"
    ) {
      selectInfo.view.calendar.unselect();
      setState({ selectInfo, state: "create" });
      console.log("open modal create");
      setStart(selectInfo.start);
      setEnd(selectInfo.end);
      setModal(true);
    }
  }
  const zeroPad = (num, places) => String(num).padStart(places, '0')
  function renderEventContent(eventInfo) {

    var startHour = zeroPad(eventInfo.event.start.getHours(),2);
    var startMinute = zeroPad(eventInfo.event.start.getMinutes(),2);
    var endHour = zeroPad(eventInfo.event.end?.getHours() || eventInfo.event.start.getHours(),2);
    var endMinute = zeroPad(eventInfo.event.end?.getMinutes() || eventInfo.event.start.getMinutes(),2);
    
    return (
      <>
          <div className="fc-daygrid-event-dot"></div>
            <div className="fc-event-time" style={{ marginLeft: "5px" }}>{startHour}:{startMinute}-{endHour}:{endMinute}-{eventInfo.event.extendedProps.issuedBy}</div><br></br>
            <div className="fc-event-title">{eventInfo.event.title}</div>
      </>
    );
  }
  function handleEventClick(clickInfo) {
      setState({ clickInfo, state: "update" });
      // set detail
      let e= allEvents.filter(function(item){
        if(item.id==clickInfo.event.id){
        
          let id=sessionStorage.getItem('userId');
          if(item.userId==id){
            setDetail(false);
          }
          else{
            setDetail(true);

          }
          
          return(item);
        }
      })
      setTitle(clickInfo.event.title);
      setStart(clickInfo.event.start);
      let enddate=new Date(e[0].end)
      setEnd(enddate);
      setModal(true);

  }
  function handleEvents(events) {
    setCurrentEvents(events);
  }
  function handleEventDrop(checkInfo) {
    setState({ checkInfo, state: "drop" });
    setConfirmModal(true);
  }
  function handleEventResize(checkInfo) {
    setState({ checkInfo, state: "resize" });
    setConfirmModal(true);
  }
  function handleEdit() {
    const id = state.clickInfo.event.id;
    const update = {
      title: title,
      start: state.selectInfo?.startStr || start.toISOString(),
      end: state.selectInfo?.endStr || end.toISOString(),
      allDay: state.selectInfo?.allDay || false,
    };
    EventAPI.update({ id, update });
    state.clickInfo.event.setStart(start);
    state.clickInfo.event.setEnd(end);
    state.clickInfo.event.mutate({
      standardProps: { title },
    });
    handleClose();
  }
  function handleSubmit() {
    const post = {
      title: title,
      start: state.selectInfo?.startStr || start.toISOString(),
      end: state.selectInfo?.endStr || end.toISOString(),
      allDay: state.selectInfo?.allDay || false,
      global:true
    };

    let calendarApi = calendarRef.current.getApi();
    EventAPI.addEvent(post).then((data) => {
      const newEvent = {
        id: data.id,
        issuedBy:data.issuedBy,
        title,
        start: state.selectInfo?.startStr || start.toISOString(),
        end: state.selectInfo?.endStr || end.toISOString(),
        allDay: state.selectInfo?.allDay || false,
        global:true,
        userId:data.userId
      };
      calendarApi.addEvent(newEvent);
      allEvents.push(newEvent);
    })
    .catch(function (ex) {});;
    handleClose();
  }
  function addHours(date, hours) {
    date.setHours(date.getHours() + hours);
    return date;
  }
  function drop() {
    const id = state.checkInfo.event.id;
    const update = {
      title: state.checkInfo.event.title,
      start:state.checkInfo.event.start.toISOString(),
      end: state.checkInfo.event.end.toISOString(),
      allDay: state.checkInfo.event.allDay,
    };
    EventAPI.update({ id, update });
  }
  function handleDelete() {
    let id = state.clickInfo.event.id;
    EventAPI.delete({ id });
    state.clickInfo.event.remove();
    handleClose();
  }
  function handleClose() {
    setTitle("");
    setStart(new Date());
    setEnd(new Date());
    setState({});
    setModal(false);
  }
  const [state, setState] = useState({});

  return (
    <div>
      <br />
      <Container>
        <Row style={{ marginBottom: 20 }}>
          <Col
            sm={{ size: 3 }}
            md={{ size: 3 }}
            style={{
              paddingLeft: 15,
            }}
          >
          </Col>
          <Col
            sm={{ size: 3, offset: 6 }}
            md={{ size: 3, offset: 6 }}
            style={{
              paddingRight: 15,
            }}
          >
            <Button
              style={{ float: "right" }}
              color="secondary"
              onClick={() => setModal(true)}
            >
              Thêm lịch
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FullCalendar
              locale={esLocale}
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={layout}
              buttonText={{
                today: "Hôm nay",
                month: "Tháng",
                week: "Tuần",
                day: "Ngày",
                list: "list",
              }}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              dayHeaderContent={ function(arg) {
                return DAY_NAMES[arg.date.getDay()]
              }}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={weekendsVisible}
              contentHeight={'auto'}
              events={allEvents}
              select={handleDateSelect}
              eventContent={renderEventContent}
              eventClick={handleEventClick}
              eventsSet={() => handleEvents(events)}
              eventDrop={handleEventDrop}
              eventResize={handleEventResize}
              dateClick={handleDateClick}
              eventAdd={(e) => {
                console.log("eventAdd", e);
              }}
              eventChange={(e) => {
                console.log("eventChange", e);
              }}
              eventRemove={(e) => {
                console.log("eventRemove", e);
              }}
            />
          </Col>
        </Row>
      </Container>

      <CustomModal
        title={state.state === "update" ? "Sửa" : "Thêm Lịch"}
        isOpen={modal}
        toggle={handleCloseModal}
        onCancel={handleCloseModal}
        onSubmit={state.clickInfo ? handleEdit : handleSubmit}
        submitText={state.clickInfo ? "Lưu" : "Lưu"}
        onDelete={state.clickInfo && handleDelete}
        deleteText="Xóa"
        detail={detail}
      >
        <FormGroup>
          <Label for="exampleEmail">Nội dung</Label>
          <Input
            type="text"
            name="title"
            placeholder="Nội dung"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Thời gian</Label>
          <DateRangePicker
            initialSettings={{
              locale: {
                format: "M/DD hh:mm A",
              },
              startDate: start,
              endDate: end,
              timePicker: true,
            }}
            onHide={(event, picker) => {
              // console.log(
              //   "picker",
              //   picker.startDate.toISOString(),
              //   picker.endDate.toISOString()
              // );
              setStart(new Date(picker.startDate));
              setEnd(new Date(picker.endDate));
            }}
          >
            <input className="form-control" type="text" />
          </DateRangePicker>
        </FormGroup>
      </CustomModal>

      <CustomModal
        title={state.state === "resize" ? "Resize Event" : "Di chuyển"}
        isOpen={confirmModal}
        toggle={() => {
          state.checkInfo.revert();
          setConfirmModal(false);
        }}
        onCancel={() => {
          state.checkInfo.revert();
          setConfirmModal(false);
        }}
        cancelText="Hủy"
        onSubmit={() => {
          drop();
          setConfirmModal(false);
        }}
        submitText={"Lưu"}
      >
        Bạn có muốn muốn di chuyển sự kiện?
      </CustomModal>
    </div>
  );
}
