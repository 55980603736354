const API = process.env.REACT_APP_API_URL;
const tokenString = sessionStorage.getItem('token');
const token = JSON.parse(tokenString);
class User {    
    signIn(data){
        console.log(data);
        return fetch(API+'/UserAuth/login',{ 
            method: 'post',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then(res => res.json());        
    }
    signUp(data){
        console.log(data);
        return fetch(API+'/UserAuth/register',{ 
            method: 'post',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then(res => res.json());        
    }

    getAll(params){        
        var url = new URL(API+'/Users/GetAll');        
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        return fetch(url,{ 
            method: 'get',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
            }
        })
        .then(res => res.json()); 
    }
    deleteUser(params){
        var url = new URL(API+'/Users/DeleteUser');    
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        return fetch(url,{ 
            method: 'post',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        })
        .then(res => res.json());   
    }
    modifyUser(params){
        var url = new URL(API+'/Users/ModifyUser');    
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        return fetch(url,{ 
            method: 'post',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
            }
        })
        .then(res => res.json());        
    }
    getUserByName(){
        var url = new URL(API+'/Users/GetUserByName');    
        return fetch(url,{ 
            method: 'post',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
            }
        })
        .then(res => res.json());        
    }

}

export default new User();