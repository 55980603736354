import { Routes, Route,BrowserRouter} from 'react-router-dom';
import Menu from './Components/Menu';
import Login from './Page/Login';
import Calendar from './Page/Calendar';
import CalendarAll from './Page/CalendarAll';
import ListUser from './Page/ListUser'
import ChangeInf from './Page/ChangeInf'

import useToken from './useToken';
function App() {
  const { token, setToken } = useToken();
  const roleString = sessionStorage.getItem('role');
  if(!token) {
    return <Login setToken={setToken} />
  }
  if(roleString==='Quản trị'){
    return (
      <>
        <BrowserRouter>
          <Menu/>        
          <Routes>
            <Route path="/" element={<CalendarAll />} />
          </Routes>
          <Routes>
            <Route path="/Calendar" element={<Calendar />} />
          </Routes>
          <Routes>
            <Route path="/ListUser" element={<ListUser />} />
          </Routes>
        </BrowserRouter>
      </>
    );
  }
  return (
    <>
      <BrowserRouter>
        <Menu/>        
        <Routes>
          <Route path="/" element={<CalendarAll />} />
        </Routes>
        <Routes>
          <Route path="/Calendar" element={<Calendar />} />
        </Routes>
        <Routes>
            <Route path="/ChangeInf" element={<ChangeInf />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;