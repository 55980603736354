const API = process.env.REACT_APP_API_URL;
class EventAPI {    
    GetAll(){
        return fetch(API+'/Event',{ 
            method: 'get',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
            }
        })
        .then(res => res.json());        
    }
    GetById(data){
        return fetch(API+'/Event/'+data.id,{ 
            method: 'get',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
            }
        })
        .then(res => res.json());        
    }
    GetAlls(){
        return fetch(API+'/EventsAll',{ 
            method: 'get',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
            }
        })
        .then(res => res.json());        
    }
    addEvent(data){
        return fetch(API+'/Event',{ 
            method: 'post',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
            },
            body: JSON.stringify(data)
        })
        .then(res => res.json()); 
    }
    delete(data){
        let url=API+'/Event/'+data.id;
        return fetch(url,{ 
            method: 'delete',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
            }
        })
        .then(res => res.json()); 
    }
    update(data){
        let url=API+'/Event/'+data.id
        return fetch(url,{ 
            method: 'put',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
            },
            body: JSON.stringify(data.update)
        })
        .then(res => res.json()); 
    }

}

export default new EventAPI();